<template>
  <exterior-page-content-container>
    <div class="flex flex-col items-center h-64 mb-24">
      <p class='mb-3 text-2xl font-bold text-center text-primary'>Reset Password</p>

      <notification-banner
        v-if="error.length > 0"
        color="red"
      >
        {{ error }}
      </notification-banner>

      <notification-banner
        v-if="passwordReset"
        color="green"
      >
        Your password has been reset! You can click
        <a
          class="underline cursor-pointer"
          @click="$router.push({name: 'SignIn'})"
        >Sign in</a> to sign in with your new password
      </notification-banner>

      <div>
        <text-input-component
          v-show="!passwordReset"
          :value.sync="password"
          placeholder="New Password"
          :valid="passwordValid"
          type='password'
          label="New Password"
          mode="form"
          class="mt-2"
        />

        <text-input-component
          v-show="!passwordReset"
          :value.sync="confirmPassword"
          placeholder="Confirm Password"
          :valid="confirmPasswordValid"
          type='password'
          label="Confirm Password"
          mode="form"
          class="mt-2"
        />
      </div>

      <button-large
        v-show="!passwordReset"
        class='mt-5'
        @click="resetPassword"
        color="primary"
      >
        Update Password
      </button-large>

    </div>
  </exterior-page-content-container>
</template>

<script>

import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';
import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import { USER_RESET_PASSWORD } from '../store/actions';
import ButtonLarge from '../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ResetPassword',
  components: {
    ExteriorPageContentContainer,
    NotificationBanner,
    TextInputComponent,
    ButtonLarge,
  },
  data() {
    return {
      password: '',
      passwordValid: true,
      confirmPassword: '',
      confirmPasswordValid: true,
      passwordReset: false,
      error: '',

    };
  },
  methods: {
    resetPassword() {
      if (this.passwordValidation()) {
        this.$store.dispatch(USER_RESET_PASSWORD, {
          token: this.$route.query.reset_password_token,
          password: this.password,
        })
          .then(() => {
            this.passwordReset = true;
          })
          .catch((data) => {
            if (data.response.status === 401) {
              this.error = 'Password reset link has expired. Please generate a new one';
            } else {
              this.error = 'An error has occured, please try again later';
            }
          });
      }
    },
    passwordValidation() {
      this.passwordValid = true;
      this.confirmPasswordValid = true;
      this.error = '';
      if (this.password !== this.confirmPassword) {
        this.error = 'Your password must match';
        this.confirmPasswordValid = false;
      } else if (this.password.length < 7) {
        this.error = 'Your password must be greater than 7 characters';
        this.passwordValid = false;
      } else {
        return true;
      }
      return false;
    },
  },
  watch: {
    confirmPassword() {
      if (this.error.length > 0) {
        this.passwordValidation();
      }
    },
    password() {
      if (this.error.length > 0) {
        this.passwordValidation();
      }
    },
  },
};
</script>
